import {get, post} from './request'

let apiFun = {};
apiFun.postLogin = p => post('/v1/login', p, "form")
apiFun.getCurrentUserInfo = p => get('/v1/userInfo', p, "json")
apiFun.postAddPatient= p => post('/team/work/v1/add', p, "form")
apiFun.postPatientList= p => post('/team/work/v1/list', p, "json")
apiFun.postConfirmTransTo= p => post('/team/work/v1/flow', p, "form")
apiFun.postConfirmGiveTo= p => post('/team/work/v1/allocation', p, "form")
apiFun.postPatientCheckList = p => post('/patient/detail/v1/list', p, "json")
apiFun.postOperationList = p => post('/interview/detail/v1/operate/list', p, "json")
apiFun.postPatientDelete = p => post('/patient/detail/v1/delete', p, "form")
apiFun.postPatientCheckDelete = p => post('/interview/detail/v1/delete', p, "form")
apiFun.postUploadFile = p => post('/file/v1/upload', p, "file")
apiFun.getFileList = p => get('/interview/detail/v1/file/list', p, "json")
apiFun.postPatientPrintTime = p => post('/interview/report/v1/update/printTime', p, "form")
apiFun.postReportAdd = p => post('/interview/report/v1/add', p, "json")
apiFun.postReportDelete = p => post('/interview/report/v1/delete', p, "form")
apiFun.postReportEdit = p => post('/interview/report/v1/update', p, "json")
apiFun.getReportDetail = p => get('/interview/report/v1/detail', p, "json")
apiFun.postMedicalInstitutionList= p => post('/hospital/v1/list', p, "json")
apiFun.postMedicalInstitutionAdd = p => post('/hospital/v1/add', p, "form")
apiFun.postMedicalInstitutionEdit = p => post('/hospital/v1/update', p, "form")
apiFun.getMedicalInstitutionDetail = p => get('/hospital/v1/detail', p, "form")
apiFun.postMedicalInstitutionDelete = p => post('/hospital/v1/delete', p, "form")
apiFun.postMedicalInstitutionBind = p => post('/hospital/v1/bind/super', p, "form")
apiFun.postMedicalInstitutionUnBind = p => post('/hospital/v1/unbind/super', p, "form")
apiFun.postMedicalInstitutionBindList = p => post('/hospital/v1/bind/super/list', p, "json")
apiFun.getSearchBind = p => get('/hospital/v1/bind/super/names', p, "json")
apiFun.postUserList = p => post('/user/v1/list', p, "json")
apiFun.postUserAdd = p => post('/user/v1/add', p, "form")
apiFun.postUserEdit = p => post('/user/v1/update', p, "form")
apiFun.getUserDetail = p => get('/user/v1/detail', p, "form")
apiFun.postUserDelete = p => post('/user/v1/delete', p, "form")
apiFun.getDictOfRole = p => get('/dict/v1/type', p, "json")
apiFun.postHostList = p => post('/host/v1/list', p, "json")
apiFun.postHostAdd = p => post('/host/v1/add', p, "form")
apiFun.postHostEdit = p => post('/host/v1/update', p, "form")
apiFun.getHostDetail = p => get('/host/v1/detail', p, "form")
apiFun.postHostDelete = p => post('/host/v1/delete', p, "form")
apiFun.getNDIRestartOfLast = p => get('/windows/v1/web/lastQuitInfo ', p, "json")

export default apiFun;
