import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);
// 用Vuex.Store对象用来记录token
const store = new Vuex.Store({
    state: {
        // 存储token
        token: "",
        userName: "", // 可选
        userId: "",
        userNameReal: "",
        userHospital: "",
        activeIndex:0,
    },
    getters: {
        getToken(state) {
            return state.token || localStorage.getItem("token") || "";
        }
    },
    mutations: {
        // 修改token，并将token存入localStorage
        setToken(state, token) {
            state.token = token;
            localStorage.setItem('token', token);
        },
        delToken(state) {
            state.token = "";
            localStorage.removeItem("token");
        },
        // 可选
        setUserInfo(state, userName) {
            state.userName = userName;
        },
        setUserId(state, id) {
            state.userId = id;
        },
        setUserName(state, name) {
            state.userNameReal = name;
        },
        setUserHospital(state, hospital) {
            state.userHospital = hospital;
        },
        setCurrentActiveBar(state, activeIndex) {
            state.activeIndex = activeIndex;
        },
        getCurrentActiveBar(state) {
            return state.activeIndex;
        }
    },

    actions: {
        // removeToken: (context) => {
        // context.commit('setToken')
        // }
    },
});

export default store;