import Vue from 'vue'
import './assets/css/global.scss'
import App from './App.vue'
import router from "./router"
import './plugins/element.js'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import { detectZoom } from '@/utils/detectZoom.js';
import request from '@/api/request.js'
import store from '@/api/store.js'
import apiFun from "@/api/api";


Vue.prototype.$request = request;
Vue.prototype.$store = store;
Vue.prototype.$apiFun = apiFun;//请求接口api

const m = detectZoom();
document.body.style.zoom = 100 / Number(m);
//判断屏幕是否为4k
if (window.screen.width * window.devicePixelRatio >=3840) {
  switch (m) {
    // 4k屏时屏幕缩放比为100%
    case 100:
      document.body.style.zoom = 100 / 50;
      break;
      // 4k屏时屏幕缩放比为125%
    case 125:
      document.body.style.zoom = 100 / 62.5;
      break;
      // 4k屏时屏幕缩放比为150%
    case 150:
      document.body.style.zoom = 100 / 75;
      break;
      // 4k屏时屏幕缩放比为175%
    case 175:
      document.body.style.zoom = 100 / 87.4715;
      break;
      // 4k屏时屏幕缩放比为200%
    case 200:
      document.body.style.zoom = 100 / 100;
      break;
      // 4k屏时屏幕缩放比为225%
    case 225:
      document.body.style.zoom = 100 / 112.485;
      break;
    default:
      break;
  }
  document.body.style.zoom = 100 / (Number(m)/2);
}else{
  document.body.style.zoom = 100 / Number(m);
}
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  render: h => h(App),

  store,
  router,
  ElementUI
}).$mount('#app')
