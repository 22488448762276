import Vue from "vue"
import VueRouter from "vue-router"

const routerPush = VueRouter.prototype.push;

//重复跳转路由
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {
    })
};

Vue.use(VueRouter)

const routes = [
    {
        // 进页面的动画页面
        path: '/',
        component: () => import('../views/Login.vue'),
    },
    //  {
    //     //阅片预览编辑
    //     path: '/readfilm_edit/:id',
    //     name: 'readFilmEdit',
    //     component: () => import('../views/readfilm/Edit.vue')
    // }, {
    //     //登记-患者详情-对比
    //     path: '/compareExamine/:id',
    //     name: 'comparison',
    //     component: () => import('../views/register/Comparison.vue')
    // },
    {
        //主页
        path: '/home',
        component: () => import('../views/home/Home.vue'),
        redirect: '/home/teamwork',
        children: [
            {
                //协作阅片
                path: 'teamwork',
                component: () => import('../views/teamwork/index.vue'),

            },
            {
                //协作阅片-新增-
                path: 'teamworkAdd',
                component: () => import('../views/teamwork/add.vue'),

            },
            {
                //协作阅片-患者详情
                path: 'teamworkInfo',
                name: 'teamworkInfo',
                component: () => import('../views/teamwork/readfilm/Info.vue'),

            },
            {
                //协作阅片-患者详情-检查详情
                path: 'examineDetail',
                name: 'examineDetail',
                component: () => import('../views/teamwork/examine/Info.vue'),

            },
            {
                //协作阅片-患者详情-报告
                path: 'readfilmReport',
                name: 'readfilmReport',
                component: () => import('../views/teamwork/readfilm/CheckReport.vue'),

            },
            {
                //云端用户
                path: 'clouduser',
                component: () => import('../views/clouduser/index.vue'),

            },
            {
                //新增云端用户
                path: 'clouduserAdd',
                component: () => import('../views/clouduser/add.vue'),

            },
            {
                //编辑云端用户
                path: 'clouduserEdit',
                name: 'clouduserEdit',
                component: () => import('../views/clouduser/edit.vue'),

            },
            {
                //医疗结构
                path: 'medicalinstitution',
                component: () => import('../views/medicalinstitution/index.vue'),
            },
            {
                //新建医疗结构
                path: 'medicalinstitutionAdd',
                component: () => import('../views/medicalinstitution/add.vue'),
            },
            {
                //编辑医疗结构
                path: 'medicalinstitutionEdit',
                name: 'medicalinstitutionEdit',
                component: () => import('../views/medicalinstitution/edit.vue'),
            },
            {
                //客户主机
                path: 'hostclient',
                component: () => import('../views/hostclient/index.vue'),

            },
            {
                //新建客户主机
                path: 'addhost',
                component: () => import('../views/hostclient/addHost.vue'),
            },
            {
                //修改客户主机
                path: 'editHost',
                name: 'editHost',
                component: () => import('../views/hostclient/editHost'),

            },
            {
                //编辑客户主机
                path: 'hostclientEdit',
                component: () => import('../views/clouduser/edit.vue'),

            },
            // {
            //     //新增登记
            //     path: 'register/add',
            //     component: () => import('../views/register/Add.vue'),
            // },
            // {
            //     //患者修改
            //     path: 'register/edit/:id',
            //     name: 'patientEdit',
            //     component: () => import('../views/register/Add.vue'),
            // },


            // {
            //     //阅片报告
            //     path: 'readfilm/report/:id',
            //     name: 'readfilmReport',
            //     component: () => import('../views/report/CheckReport.vue')
            // },
            // {
            //     //检索
            //     path: 'retrieval',
            //     component: () => import('../views/retrieval/Retrieval.vue')
            // },
            // {
            //     //导入导出记录
            //     path: 'ioPut',
            //     component: () => import('../views/retrieval/IORecord.vue')
            // },
            // {
            //     //设置
            //     path: 'setting',
            //     component: () => import('../views/setting/Setting.vue')
            // },
            // {
            //     //关于
            //     path: 'setting/about',
            //     component: () => import('../views/setting/about/About.vue')
            // },
            // {
            //     //运维
            //     path: 'setting/opt',
            //     component: () => import('../views/setting/opt/OPT.vue')
            // },
            // {
            //     //滤镜
            //     path: 'setting/filter',
            //     component: () => import('../views/setting/filter/FilterOpt.vue')
            // },
            // {
            //     //用户
            //     path: 'setting/user',
            //     component: () => import('../views/setting/user/User.vue')
            // },
            // {
            //     //添加医生用户
            //     path: 'setting/user/add',
            //     component: () => import('../views/setting/user/Add.vue')
            // },

            // {
            //     //医生用户修改-删除
            //     path: 'setting/user/edit/:ids',
            //     name: 'userEdit',
            //     component: () => import('../views/setting/user/Add.vue')
            // },
            // {
            //     //医院列表
            //     path: 'setting/hospital',
            //     component: () => import('../views/setting/user/hospital/Hospital')
            // },
            // {
            //     //医院添加
            //     path: 'setting/hospital/add',
            //     component: () => import('../views/setting/user/hospital/Add')
            // },
            // {
            //     //医院修改-删除
            //     path: 'setting/hospital/edit/:ids',
            //     name: 'hospitalEdit',
            //     component: () => import('../views/setting/user/hospital/Add')
            // },
            // {
            //     //医院明细
            //     path: 'setting/hospital/info/:id',
            //     name: 'hospitalDetail',
            //     component: () => import('../views/setting/user/hospital/Info')
            // }
        ]
    },
    {
        //协作阅片-患者详情-对比
        path: 'comparison',
        name: 'comparison',
        component: () => import('../views/teamwork/readfilm/Comparison.vue'),

    },
    {
        //协作阅片-患者详情-检查详情-阅片
        path: 'readFilmEdit',
        name: 'readFilmEdit',
        component: () => import('../views/teamwork/readfilm/Edit.vue'),

    }
]

const router = new VueRouter({
    mode: "history",
    routes
})

export default router